// Generates spacing classes similar to the ones that bootstrap uses
// Examples -> `margin-top-xs`, `margin-right-lg`, `padding-bottom-md`

$spacer: 1rem;

@mixin spacing-classes() {
  $spacing-values: (
    xs: 0.25,
    // 4px
    sm: 0.5,
    // 8px
    md: 1,
    // 16px
    lg: 1.25,
    // 20px
    xl: 1.5 // 24px
  );

  @each $size, $multiplier in $spacing-values {
    $class-name: #{$size};

    .margin-top-#{$class-name} {
      margin-top: ($spacer * $multiplier);
    }

    .margin-right-#{$class-name} {
      margin-right: ($spacer * $multiplier);
    }

    .margin-bottom-#{$class-name} {
      margin-bottom: ($spacer * $multiplier);
    }

    .margin-left-#{$class-name} {
      margin-left: ($spacer * $multiplier);
    }

    .margin-x-#{$class-name} {
      margin-left: ($spacer * $multiplier);
      margin-right: ($spacer * $multiplier);
    }

    .margin-y-#{$class-name} {
      margin-top: ($spacer * $multiplier);
      margin-bottom: ($spacer * $multiplier);
    }

    .margin-all-#{$class-name} {
      margin: ($spacer * $multiplier);
    }

    .padding-top-#{$class-name} {
      padding-top: ($spacer * $multiplier);
    }

    .padding-right-#{$class-name} {
      padding-right: ($spacer * $multiplier);
    }

    .padding-bottom-#{$class-name} {
      padding-bottom: ($spacer * $multiplier);
    }

    .padding-left-#{$class-name} {
      padding-left: ($spacer * $multiplier);
    }

    .padding-x-#{$class-name} {
      padding-left: ($spacer * $multiplier);
      padding-right: ($spacer * $multiplier);
    }

    .padding-y-#{$class-name} {
      padding-top: ($spacer * $multiplier);
      padding-bottom: ($spacer * $multiplier);
    }

    .padding-all-#{$class-name} {
      padding: ($spacer * $multiplier);
    }
  }
}
