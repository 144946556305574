@use './mixins/spacingMixin.scss' as spacing;

// Initialize mixins
@include spacing.spacing-classes();

:root {
  --ion-color-primary: #00cf95;
  --ion-color-primary-rgb: 0, 207, 149;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00a269;
  --ion-color-primary-tint: #d7f7e9;

  --ion-color-secondary: #2d3b4e;
  --ion-color-secondary-rgb: 45, 59, 78;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0f161e;
  --ion-color-secondary-tint: #616c7a;
}

* {
  --ion-font-family: 'MuseoSans', Helvetica, sans-serif !important;
  font-family: 'MuseoSans', Helvetica, sans-serif !important;
  color: rgb(45, 59, 78);
}

p,
span {
  margin: 0;
}

html {
  min-height: 100%;
  height: 1px;
  box-sizing: border-box;
}

button {
  outline: none !important;
}

* body,
* main,
* #root,
.App {
  height: 100%;
  margin: 0px;
  background-color: rgb(244, 245, 246);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'kern';
  font-kerning: normal;
  overflow-x: hidden;
}

body {
  // This space is required to handle the safe area for all components (including widget modals)
  margin-top: var(--ion-safe-area-top);
  background: #fff;
}

a {
  text-decoration: none;
  color: inherit !important;
}

.loading-modal {
  --spinner-color: #2d3b4e;
}

.styled-toast {
  --background: rgb(45, 59, 78);
  color: white;
}
.styled-toast-notify {
  --background: #2d3b4e;
  color: white;
  --max-width: 320px;
  --width: 100%;
  padding: 15px;

  &.text-align-center {
    text-align: center;
  }
}

.styled-toast-error {
  --background: #f27470;
  color: white;
  text-align: center;
}

.styled-toast-black {
  color: #fff;
  font-weight: 600;
  text-align: center;

  --background: #0f161e;
  --width: fit-content;
}

/* Set the icon color and opacity */
ion-select::part(icon) {
  right: 10px;
  opacity: 0.7;
}

/* SPLASH SCREEN */

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.splash-container {
  background-color: #00cf95;
  display: block;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 3s;
}

.transition-right {
  transform: translateX(280%);
  transition: all 2s cubic-bezier(1, 0.12, 0.34, 0.855);
}

.transition-left {
  transform: translateX(-280%);
  transition: all 2s cubic-bezier(1, 0.12, 0.34, 0.855);
}

/* MUSEO Font */

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/MuseoSans_500.ttf');
}

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/MuseoSans_700.ttf');
}

@font-face {
  font-family: 'MuseoSans';
  font-style: italic;
  font-weight: normal;
  src: url('./assets/fonts/MuseoSans_500_Italic.ttf');
}

/* Braze content cards */
.ab-feed.ab-effect-slide.ab-show {
  width: 100%;
}

.ab-feed .ab-feed-buttons-wrapper {
  display: none;
}

.ab-feed {
  background-color: transparent !important;
  box-shadow: none !important;
}

.ab-feed .ab-feed-body {
  padding: 0 !important;
  border: none !important;
}

.ab-no-cards-message {
  display: none;
}

.ab-feed .ab-card {
  &:last-child {
    margin-bottom: 0;
  }
}

// Misc
.form-field-error {
  color: rgba(242, 116, 112, 1);
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  margin-top: 8px;

  img {
    margin-right: 4px;
  }
}

.text-center {
  text-align: center;
}

.green-text {
  color: #00cf95;
}

.bold {
  font-weight: 600;
}
