.slbOverlay,
.slbWrapOuter,
.slbWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.slbOverlay {
  overflow: hidden;
  z-index: 2000;
  background-color: #171f2b;
}
.slbWrapOuter {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2010;
}
.slbWrap {
  position: absolute;
  text-align: center;
}
.slbWrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.slbContentOuter {
  display: inline-block;
  vertical-align: middle;
  margin: 0px auto;
  padding: 0 1em;
  box-sizing: border-box;
  z-index: 2020;
  text-align: left;
  max-width: 100%;
  width: 100%;
}
.slbContentEl .slbContentOuter {
  padding: 5em 1em;
}
.slbContent {
  position: relative;
}
.slbContentEl .slbContent {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  background-color: #fff;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.4);
}
.slbImageWrap {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  position: relative;
}
.slbImageWrap:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 5em;
  bottom: 5em;
  display: block;
  z-index: -1;
}
.slbDirectionNext .slbImageWrap {
  -webkit-animation: slbEnterNext 0.4s;
  -moz-animation: slbEnterNext 0.4s;
  animation: slbEnterNext 0.4s;
}
.slbDirectionPrev .slbImageWrap {
  -webkit-animation: slbEnterPrev 0.4s;
  -moz-animation: slbEnterPrev 0.4s;
  animation: slbEnterPrev 0.4s;
}
.slbImage {
  width: auto;
  max-width: 100%;
  max-height: 600px !important;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 5em 0;
  margin: 0 auto;
}
.slbCaption {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-size: 16px;
  margin-bottom: -50px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  height: 100%;
}
.slbCloseBtn,
.slbArrow {
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
}
.slbCloseBtn::-moz-focus-inner,
.slbArrow::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.slbCloseBtn:hover,
.slbArrow:hover {
  opacity: 0.5;
}
.slbCloseBtn:active,
.slbArrow:active {
  opacity: 0.8;
}
.slbCloseBtn {
  position: absolute;
  font-size: 20px;
  right: 20px;
  top: 28px;
  color: #fff;
  text-align: center;
}
.slbLoading .slbCloseBtn {
  display: none;
}
.slbLoadingText {
  font-size: 1.4em;
  color: #fff;
  color: rgba(255, 255, 255, 0.9);
}
.slbArrows {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
}
.slbLoading .slbArrows {
  display: none;
}
.slbArrow {
  position: absolute;
  top: 50%;
  margin-top: -3em;
  width: 64px;
  height: 64px;
  text-indent: -999em;
  overflow: hidden;
}
.slbArrow:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.8em 0 0 -0.8em;
  border: 0.8em solid transparent;
}
.slbArrow.next {
  right: 0;
}

.slbArrow.prev {
  left: 0;
}

.slbIframeCont {
  width: 80em;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 5em 0;
}
.slbIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
  background: #000;
}
@-webkit-keyframes slbOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
@-moz-keyframes slbOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
@keyframes slbOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
@-webkit-keyframes slbEnter {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -1em, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slbEnter {
  from {
    opacity: 0;
    -moz-transform: translate3d(0, -1em, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}
@keyframes slbEnter {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -1em, 0);
    -moz-transform: translate3d(0, -1em, 0);
    -ms-transform: translate3d(0, -1em, 0);
    -o-transform: translate3d(0, -1em, 0);
    transform: translate3d(0, -1em, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slbEnterNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slbEnterNext {
  from {
    opacity: 0;
    -moz-transform: translate3d(4em, 0, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}
@keyframes slbEnterNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(4em, 0, 0);
    -moz-transform: translate3d(4em, 0, 0);
    -ms-transform: translate3d(4em, 0, 0);
    -o-transform: translate3d(4em, 0, 0);
    transform: translate3d(4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slbEnterPrev {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slbEnterPrev {
  from {
    opacity: 0;
    -moz-transform: translate3d(-4em, 0, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}
@keyframes slbEnterPrev {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-4em, 0, 0);
    -moz-transform: translate3d(-4em, 0, 0);
    -ms-transform: translate3d(-4em, 0, 0);
    -o-transform: translate3d(-4em, 0, 0);
    transform: translate3d(-4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.customCloseButton {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  top: calc(var(--ion-safe-area-top) + 15px);
}

.customNextButton {
  background-image: url('../../../../../assets/icons/rightWhiteShadowArrow.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.customPrevButton {
  background-image: url('../../../../../assets/icons/leftWhiteShadowArrow.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.lsb-title {
  width: 100%;
  color: white;
  position: absolute;
}

@media (max-width: 768px) {
  .lsb-title {
    width: auto;
  }
}

.lsb-subtitle {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.image-description {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
