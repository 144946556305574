$accentColor: #6ae2a6;
$borderRadius: 3px;

.contentCard {
  position: relative;
  background-color: #fff;
  margin-bottom: 16px;
  box-shadow: 0 2px 3px rgba(178, 178, 178, 0.5);
  -webkit-box-shadow: 0 2px 3px 0 rgb(178, 178, 178);
  border: 1px solid #d0d0d0;
  border-radius: $borderRadius;

  &:last-child {
    margin-bottom: 0;
  }

  &.unread {
    border-bottom: 3px solid $accentColor;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }

  .title {
    font-size: 16px;
    margin: 0;
    font-weight: 700;
    word-wrap: break-word;
    text-overflow: ellipsis;
    line-height: 130%;
  }

  .description {
    font-size: 13px;
    color: #545454;
    word-wrap: break-word;
    margin-top: 8px;
  }

  .closeIcon {
    width: 26px;
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 100;
    opacity: 0.3;
    padding: 8px;
    box-sizing: content-box;
  }

  .domain {
    @extend .description;

    color: $accentColor;
    font-weight: 600;
  }
}

//
// Content Card Types
//
.classic-contentCard {
  @extend .contentCard;

  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px 25px;

  .image {
    width: 60px;
  }
}

.captioned-contentCard {
  @extend .contentCard;

  .image {
    width: 100%;
  }

  .content {
    padding: 20px 25px;
  }
}

.banner-contentCard {
  @extend .contentCard;

  .image {
    width: 100%;
    display: block;
  }
}
