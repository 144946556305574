.message {
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(242, 116, 112, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 22px 12px;
  min-height: 53px;
  margin-top: 0px;
  width: 100%;
  height: 100%;
  cursor: auto;
  flex-wrap: wrap;
  margin-bottom: 34px;

  @media (max-width: 767px) {
    padding: 24px;
  }

  a {
    white-space: pre-wrap;
  }

  .error-message {
    margin: 0 10px 0 0;
    color: #f27470;
    font-size: 16px;
    line-height: 19px;
    color: #2d3b4e;
    font-weight: 500;
    word-break: break-word;
    white-space: wrap;

    .wrong-data {
      font-weight: 700;
    }
  }

  .full-width {
    width: 100%;
  }
}
.white-spinner {
  margin: auto;
  color: #ffffff;
}
:host(.spinner-crescent) circle {
  color: #ffffff;
}
