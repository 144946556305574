/* Set the width to the full container and center the content */

/* Set the flex in order to size the text width to its content */
ion-select.language-select::part(placeholder),
ion-select.language-select::part(text) {
  flex: 0 0 auto;
}

/* Set the placeholder color and opacity */
ion-select.language-select::part(placeholder) {
  color: #2d3b4e;
  opacity: 1;
}

/*
   * Set the font of the first letter of the placeholder
   * Shadow parts work with pseudo-elements, too!
   * https://developer.mozilla.org/en-US/docs/Web/CSS/Pseudo-elements
   */
ion-select.language-select::part(placeholder)::first-letter {
  font-size: 24px;
  font-weight: 500;
}

/* Set the text color */
ion-select.language-select::part(text) {
  color: #2d3b4e;
  font-weight: 700;
  font-size: 14px;
}

/* Set the icon color and opacity */
ion-select.language-select::part(icon) {
  color: #2d3b4e;
  opacity: 1;
  right: 4px;
}

[aria-checked='true'].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios,
[aria-checked='true'].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
  border-color: #48d9a4 !important;
  --color-checked: #48d9a4;
  color: #2d3b4e !important;
}

// MOBILE STYLES

/* Set the placeholder color and opacity */
.mobile_lang_picker ion-select::part(placeholder) {
  color: white;
  opacity: 1;
}

/* Set the text color */
.mobile_lang_picker ion-select::part(text) {
  color: white;
  font-weight: 700;
  font-size: 14px;
}

/* Set the icon color and opacity */
.mobile_lang_picker ion-select::part(icon) {
  color: white;
  opacity: 1;
  right: 4px;
}

.mobile_lang_picker ion-select {
  padding: 0;
}

@media (max-width: 768px) {
  .mobile_lang_picker ion-select::part(icon) {
    display: none;
  }
}
