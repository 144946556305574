.timeline-events-container {
  min-height: calc(100% - 56px); /* 100% of screen height - 56px of toolbar height */
}

.timeline-events-container ion-content {
  --padding-end: 15px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

.timeline-message-row {
  min-height: 42px;
  margin-bottom: 16px;
  align-items: baseline;
}

/*.timeline-message-row-time {
  width: 30px;
  margin-right: 12px;
}*/

.timeline-message-row-time span {
  /*font-weight: bold;*/
  color: rgba(45, 59, 78, 0.5);
  font-family: 'MuseoSans';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.timeline-message-row-message {
  /*background: rgba(45, 59, 78, 0.05);*/
  border-radius: 8px;
  /*color: #2d3b4e;*/
  padding: 10px;
  align-self: left;
  color: #2d3b4e;
  font-family: 'MuseoSans';
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: flex-start;
}

.timeline-message-row-message span {
  padding-right: 35px;
}

.timeline-message-row-message.welcome {
  background: transparent;
  /*color: #2D3B4E;*/
  /*color: rgba(45,59,78,0.5);*/
}

.timeline-message-row-message.welcome span {
  /*color: #2D3B4E;*/
  color: rgba(45, 59, 78, 0.5);
}

.timeline-message-row-message.driver {
  background: rgba(45, 59, 78, 0.05);
}

.timeline-message-row-message.traveler {
  background: #d7f7e9;
}
