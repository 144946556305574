:root {
  --str-chat-message-options-size: 100px !important;
}

.str-chat,
.str-chat__theme-light {
  --str-chat__primary-color: #00cf95;
}

/*Limit height of list*/
.str-chat__channel-list {
  height: unset;
}

.str-chat__avatar .str-chat__avatar-fallback {
  background-color: #48d9a4;
}

.str-chat__avatar .str-chat__avatar-image {
  background: #fff;
}

/* mobile input */
@media screen and (max-width: 768px) {
  .str-chat__message-input {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  .messaging.str-chat .str-chat__list {
    padding-bottom: calc(var(--ion-safe-area-bottom) + 80px);
  }
}

.str-chat__list {
  min-height: 360px;
}

.str-chat__channel-list-team__header {
  display: none;
}

/* Chat input styling */
.str-chat__message-textarea-container {
  border-radius: 4px !important;
}
.str-chat__message-textarea-container:focus-within {
  border: 1px solid #2d3b4e !important;
}

.str-chat__message-input {
  padding-top: 10px;
  padding-bottom: calc(var(--ion-safe-area-bottom) + 10px);
  padding-right: 16px;
}

.str-chat .archived p {
  color: rgba(45, 59, 78, 0.5) !important;
}

/* Traveler message bubbles */
.str-chat__message.str-chat__message--me .str-chat__message-bubble {
  background-color: #d7f7e9;
}

/* message bubbles */
.str-chat__message .str-chat__message-bubble {
  background-color: rgba(45, 59, 78, 0.05);
}

/* Hide block with info about online presence below chat input*/
.str-chat__input-footer {
  display: none;
}

.str-chat__date-separator-line {
  background-color: #0000001a;
}

/* Hide driver's next to message */
.str-chat__message-simple-name {
  display: none;
}

/* Disable opening attachment images on fullscreen on ios app .from-ios-app*/
.from-ios-app .str-chat__message-attachment--img {
  pointer-events: none;
  touch-action: none;
}
